export default {
  vehicle: {},
  vehicleLoaded: false,
  distance: 0,
  month: 0,
  defaults: {},
  priceForm: '',
  vehicleColor: {},
  selectedAccessories: [],
  vatIncluded: false,
  deliveryType: 'delivery_type_stardard',
  residualVisibility: true,
  personNumberError: '',
  leadID: null,
  currentMonthlyPrice: null,
  downPaymentsByDistance: [],
  selectedDistance: {
    down_payment: null,
    monthly_cost: null
  }
}