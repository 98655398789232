export default {
  setVehicle: (state, vehicle) => state.vehicle = vehicle,
  setVehicleLoaded: (state, vehicleLoaded) => state.vehicleLoaded = vehicleLoaded,
  setDistance: (state, distance) => state.distance = distance,
  setMonth: (state, month) => state.month = month,
  setVehicleColor: (state, vehicleColor) => state.vehicleColor = vehicleColor,
  setSelectedAccessories: (state, selectedAccessories) => state.selectedAccessories = selectedAccessories,
  setVatIncluded: (state, vatIncluded) => state.vatIncluded = vatIncluded,
  setDeliveryType: (state, deliveryType) => state.deliveryType = deliveryType,
  setDefaults: (state, defaults) => state.defaults = defaults,
  setPriceForm: (state, form) => state.priceForm = form,
  setResidualVisibility: (state, visibility) => state.residualVisibility = visibility,
  setPersonNumberError: (state, error) => state.personNumberError = error,
  setLeadID: (state, id) => state.leadID = id,
  setCurrentMonthlyPrice: (state, monthlyPrice) => state.currentMonthlyPrice = monthlyPrice,
  setDownPaymentsByDistance: (state, downPayment) => state.downPaymentsByDistance = downPayment,
  setSelectedDistance: (state, selectedDistance) => state.selectedDistance = selectedDistance,
  setUpdateSelectedAccessoriesWithPreSelected: (state, preSelectedAccessories) => {
    const selected = [...state.selectedAccessories]
    state.selectedAccessories = [...selected, ...preSelectedAccessories]
  }
}