/* eslint-disable no-prototype-builtins */
import moment from 'moment';

export default {
  setVehicleId: (state, vehicleId) => state.vehicleId = vehicleId,
  setOrdersList: (state, ordersList) => state.ordersList = ordersList,
  setVehicleColorId: (state, vehicleColorId) => state.vehicleColorId = vehicleColorId,
  setVehicleCostId: (state, vehicleCostId) => state.vehicleCostId = vehicleCostId,
  setVehicleCostDownPaymentId: (state, downPaymentId) => state.vehicleCostDownPaymentId = downPaymentId,
  setLocationId: (state, locationId) => state.locationId = locationId,
  setAccessories: (state, accessories) => state.accessories = accessories,
  setPickUpDate: (state, pickUpDate) => state.pickUpDate = moment(pickUpDate).format('Y-MM-DD'),
  setPickUpTime: (state, pickUpTime) => state.pickUpTime = pickUpTime,
  setFirstName: (state, firstName) => state.firstName = firstName,
  setLastName: (state, lastName) => state.lastName = lastName,
  setEmail: (state, email) => state.email = email,
  setPhone: (state, phone) => state.phone = phone,
  setPersonNumber: (state, personNumber) => state.personNumber = personNumber,
  setCompanyName: (state, companyName) => state.companyName = companyName,
  setCompanyNumber: (state, companyNumber) => state.companyNumber = companyNumber,
  setAddress: (state, address) => state.address = address,
  setCoAddress: (state, coAddress) => state.coAddress = coAddress,
  setCity: (state, city) => state.city = city,
  setZipCode: (state, zipCode) => state.zipCode = zipCode,
  setComments: (state, comments) => state.comments = comments,
  setTotalMonthlyPrice: (state, totalMonthlyPrice) => state.totalMonthlyPrice = totalMonthlyPrice,
  flexSetField: (state, [key, value]) => {
    if (key in state) state[key] = value
  },
  setDrivingLicense: (state, drivingLicense) => state.drivingLicense = drivingLicense,
  setStatus: (state, status) => state.status = status,
  setResidual: (state, value) => state.residual = value,
  setCashPayment: (state, value) => state.cashPayment = value,
  setPostalCodeId: (state, code) => state.postal_code_id = code,
  setHomeDelivery: (state, value) => state.home_delivery = value,
  setTradeIn: (state, tradeObj) => (state.tradeIn = tradeObj),
  setIsTradeIn: (state, value) => (state.isTradeIn = value),
  setKalpData: (state, data) => {
    if (state.kalp_data === '') state.kalp_data = {};

    state.kalp_data[data['name']] = data.value;
  },
}