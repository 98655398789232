import Vue from 'vue'
import Vuex from 'vuex'

import FilterModule from './modules/filter/index'
import ListModule from './modules/list/index'
import ProductModule from './modules/product/index'
import StepsModule from './modules/steps/index'
import ResellerModule from './modules/reseller/index'
import OrderModule from './modules/order/index'
import GlobalModule from './modules/global/index'
import FinancingModule from './modules/financing'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    global: GlobalModule,
    filter: FilterModule,
    list: ListModule,
    product: ProductModule,
    steps: StepsModule,
    reseller: ResellerModule,
    order: OrderModule,
    financing: FinancingModule,
  }
})

export default store