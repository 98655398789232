export default {
  setType: (state, type) => state.type = [type],
  setResellerTypeId: (state, resellerTypeId) => state.resellerTypeId = resellerTypeId,
  setAllTypes: (state, types) => state.allTypes = types,
  setFilters: (state, filters) => state.filters = filters,
  setSelectedFilters: (state, selectedFilter) => {
    if (!state.selectedFilters[selectedFilter.name]) state.selectedFilters[selectedFilter.name] = [];

    const newArray = [...state.selectedFilters[selectedFilter.name], ...selectedFilter.values];

    state.selectedFilters[selectedFilter.name] = [...new Set(newArray)];
},
  setToggleMore: (state, status) => state.showMore = status,
  setDataLoaded: (state, status) => state.dataWasLoaded = status,
  setFilterShow: (state, status) => state.filterShow = status,
  setFiltersLoaded: (state, loaded) => state.filtersLoaded = loaded,
  removeSelectedFilters: (state) => state.selectedFilters = {},
  setAllFinanceForms: (state, forms) => state.allFinanceForms = forms,
  setFinanceFormId: (state, id) => state.finance_form_id = id,
  setFinanceFormName: (state, name) => state.finance_form_name = name,
  setDealerCode: (state, dealerCode) => state.dealerCode = dealerCode,
}