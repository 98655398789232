export default {
  goToNextStep({commit, state}) {
    if (state.currentStepIndex + 1 < state.steps.length)
      commit('setCurrentStepIndex', ++state.currentStepIndex)
  },
  goToPrevStep({commit, state}) {
    if (state.currentStepIndex - 1 > 0)
      commit('setCurrentStepIndex', --state.currentStepIndex)
  },
  updateSortedCosts({commit}, data) {
    commit('setSortedCosts', data);
  },
  setNfActiveStep({commit}, data) {
    commit('setNfActiveStep', data);
  },

}