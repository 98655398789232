export default {
  updateBuilder({commit, state, rootState }, id) {
    const params = {
      'financeFormId': id || rootState.filter.finance_form_id,
    };

    if (this.state.filter.type[0]) {
      params['typeId'] = this.state.filter.type[0];
    }

    // eslint-disable-next-line
    return axios.get('/reseller/builder', {
      headers: {
        'Authorization': this.state.reseller.partnerKey,
      },
      params,
    })
    .then(response => {
      if (id) {
        const style = JSON.parse(JSON.stringify(state.customStyle))
        
        style['firstStep'] = response.data.firstStep
        style['accessoryPage'] = response.data.accessoryPage
        style['orderPage'] = response.data.orderPage || style.orderPage

        if (response.data.confirmPage) {
          style['confirmPage'] = response.data.confirmPage
        }

        style['includes'] = response.data.includes
        
        commit('setCustomStyle', style)
      } else {
        commit('setCustomStyle', {...response.data, ...this.state.reseller.resellerInfo.builder})
      }
    })
    .then(() => {
      const globalPreloader = document.getElementById('global-preloader');

      globalPreloader.style.display = 'none';
    })
    .catch(response => response)
  },
  getResellerData({dispatch, commit}) {
    // eslint-disable-next-line
    return axios.post('/reseller/auth').then(response => {
      //console.log('response 6',response, 'types data', response.data.types.data)
      if(response.data.types.data.length === 0){
        this.showMaintenanceMode({commit});
        return;
      }

      commit('filter/setAllTypes', response.data.types.data, {root: true});

      let defaultType;

      // get type from url if it exists
      
      if (window.location.search && window.location.search.indexOf('type=') !== -1) {
        const type = window.location.search
          .replace(/\?/g, '')
          .split('&')
          .filter(item => item.indexOf('type=') !== -1)[0];
        
        if (type) {
          const typeValue = type.replace('type=', '');

          defaultType = response.data.types.data
            .find(item => item.type.name
              .replace(/ /g, '')
              .replace(/-/g, '')
              .replace(/[åÅ]/g, 'a')
              .replace(/[öÖ]/g, 'o')
              .toLowerCase() === typeValue.toLowerCase());
        }
      } else {
        if (window.location.pathname
          .split('/')
          .filter((i) => !!i)[0]) {
            defaultType = response.data.types.data.find(item => 
              window.location.pathname
                .split('/')
                .filter((i) => !!i)[0]
                .includes('privat')
                ? item.default_private
                : item.default_corporate
            )
          } else {
            defaultType = response.data.types.data[0]
          }
      }

      commit('filter/setType', defaultType.type.id, {root: true});
      commit('filter/setResellerTypeId', defaultType.id, {root: true});
      commit('filter/setAllFinanceForms', defaultType.financeForms.data, {root: true});

      commit('setPartnerKey', `Bearer ${response.data.token}`);
      localStorage.setItem('Authorization', `Bearer ${response.data.token}`);

      dispatch('updateResellerData', response);
    })
    .catch(response => {

      if (response.response === undefined || response.response.status === 503) {

        dispatch('showMaintenanceMode', response);
      }
    });
  },
  showMaintenanceMode({commit}) {
    const globalPreloader = document.getElementById('global-preloader');

    globalPreloader.style.display = 'none';

    commit('global/setMaintenanceMode', true, {root: true})
  },
  updateResellerData({commit}, response) {
    localStorage.removeItem('resellerInfo');
    localStorage.setItem('resellerInfo', JSON.stringify(response.data));
  
    commit('setResellerInfo', response.data);
  },
  getCustomContentData({commit}) {
    //call getResellerData
    // eslint-disable-next-line
    return axios.get('/reseller/custom-content', {
      headers: {
        'Authorization':  localStorage.getItem('Authorization'),
      },
    })
    .then(response => {

      commit('setCustomContent', response.data);

      return response;

    }) .then(() => {
        const globalPreloader = document.getElementById('global-preloader');

        globalPreloader.style.display = 'none';
      })
      .catch(response => response)


      }

}