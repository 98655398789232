export default {
  setVehicles: (state, vehicles) => state.vehicles = vehicles,
  appendVehicles: (state, vehicles) => vehicles.forEach(vehicle => {
    let allVehicleIds = state.vehicles.map(item => item.id);

    if (!allVehicleIds.includes(vehicle)) state.vehicles.push(vehicle)
  }),
  setPagination: (state, pagination) => state.pagination = pagination,
  setSelectedTypeOfSort: (state, selectedTypeOfSort) => state.selectedTypeOfSort = selectedTypeOfSort,
  setVehiclesLoaded: (state, vehiclesLoaded) => state.vehiclesLoaded = vehiclesLoaded,
}