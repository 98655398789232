import {arrayToMap} from "@/utils/map";

export default {
    getSortedCosts: (state) => state.sortedCosts,
    getPurchaseSteps: (state, _, rootState) => {
        const steps = state.nfSteps
        const accessories = rootState.product.vehicle.accessories ? rootState.product.vehicle.accessories.data : []
        const hasAcceessories = accessories.filter(acc => acc.type === 1)
        const hasTyres = accessories.filter(acc => acc.type === 2)
        const hasInsurance = accessories.filter(acc => acc.type === 3)
        const hasTradeIn = rootState.reseller.resellerInfo.settings?.tradeIn.active || false

        const stepsMap = arrayToMap(steps, 'index');
        return [
            stepsMap.get(1),
            stepsMap.get(2),
            ...(hasAcceessories.length ? [stepsMap.get(3)] : []),
            ...(hasTyres.length ? [stepsMap.get(4)] : []),
            ...(hasInsurance.length ? [stepsMap.get(5)] : []),
            ...(hasTradeIn ? [stepsMap.get(6)] : []),
            stepsMap.get(7)
        ]
    }
}