import {isMobile, } from './../../../helpers/helpers.js';

export default {
  filters: {},
  selectedFilters: {},
  allTypes: [],
  allFinanceForms: [],
  type: [],
  resellerTypeId: null,
  showMore: false,
  dataWasLoaded: false,
  filterShow: !isMobile(),
  filtersLoaded: true,
  finance_form_id: null,
  finance_form_name: '',
  dealerCode: null,
}