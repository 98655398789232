/* eslint-disable no-prototype-builtins */
import { isPrivateType, isCorporateType, getPrice, } from './../../../helpers/helpers';

let call = true;

export default {
  getVehicle({commit, }, id) {
    // eslint-disable-next-line no-unused-vars
    const {generalFormsCollectionName, } = this.state.global
    const checkFunc = generalFormsCollectionName === 'private' ? isPrivateType : isCorporateType

    commit('setVehicleLoaded', false);

    const params = {
      'financeFormId': this.state.filter.finance_form_id,
      'typeId': this.state.filter.type[0],
    }

    if (this.state.filter.dealerCode) {
      Object.assign(params, {'dealerCode': this.state.filter.dealerCode})
    }

    // eslint-disable-next-line no-undef
    return axios.get(`vehicles/${id}`, {
      headers: {
        'Authorization': this.state.reseller.partnerKey,
      },
      params
    })
      .then(response => {
        response.data.costs.data = response.data.costs.data.filter(item => {
          if (checkFunc(item.finance_form)) {
            return item
          }
        })

        commit('setVehicle', response.data);

        commit('setVehicleLoaded', true);

        return response.data
      })
      .catch((response) => response)
  },
  async updateVehicle({state, commit, dispatch, rootGetters}, data) {
    if (!call) return;

    const params = {
      'id': data.vehicleCostId
    };

    if (['Down payment', 'Corporate leasing', 'VAT credit', 'Private leasing'].includes(this.state.product.priceForm)) {
      if (this.state.product.residualVisibility) {
        params['residual'] = rootGetters['financing/residualValuePercentage']
      }

      params['cashPayment'] = rootGetters['financing/cashPaymentPercentage']

      // if (params['residual'] === '' || params['cashPayment'] === '') return;
    }

    call = false;
    
    // eslint-disable-next-line no-undef
    await axios.get(`/costs/${params.id}/calculate-dependencies`, {
      headers: {
        'Authorization': this.state.reseller.partnerKey,
      },
      params
    })
    .then(response => {
      call = true;

      const newVehicle = {
        ...state.vehicle,
        ...{
          winter_tires: response.data.winterTires,
          accessories: response.data.accessories,
          vehicleColors: response.data.vehicleColors,
          standardPrice: response.data.monthlyPrice,
          effectiveInterest: response.data.effectiveInterest,
          paymentInfo: response.data.paymentInfo,
        }
      };

      dispatch('updateDefaults', {
        'form': this.state.product.priceForm,
        'price': response.data.monthlyPrice
      })

      commit('setVehicle', newVehicle);

      return response.data
    })
      .then(responce => {
        const preSelected = responce.accessories.data
          .map(item => {
            if (!item.opt_in) {
              return item.id;
            }
          })
          .filter(item => !!item);
        if (preSelected.length) commit('setUpdateSelectedAccessoriesWithPreSelected', preSelected);

        return responce;

      })
    .catch(response => response)

  },
  setDefaultVehicleColor({state, commit}, costId) {

    const vehicleColorsArr = state.vehicle.costs.data.find(item => item.id === costId).vehicleColors
    const vehicleColor = vehicleColorsArr.data.find(item => item.default) || vehicleColorsArr.data

    commit('setVehicleColor', vehicleColor)
  },
  setCurrentMonthlyPrice({commit}, monthlyPrice) {
    commit('setCurrentMonthlyPrice', monthlyPrice)
  },
  setDownPaymentsByDistance({commit}, downpayments) {
    commit('setDownPaymentsByDistance', downpayments)
  },
  setSelectedDistance({commit}, distance) {
    commit('setSelectedDistance', distance)
  },
  setSelectedAccessories({commit}, accId) {
    commit('setSelectedAccessories', accId)
  },
  getFixedCostByMonthAndDistance({state}, [month, distance]) {
    if (state.vehicle.costs) return state.vehicle.costs.data
      .find(item => item.months === month && item.distance === distance) || {}

    return {}
  },
  clearAll({state}) {
    state.vehicle = {};
    state.vehicleLoaded = false;
    state.distance = 0;
    state.month = 0;
    state.vehicleColor = {};
    state.selectedAccessories = [];
    state.defaults = {};
    state.priceForm = '';
    state.deliveryType = 'delivery_type_stardard';
  },
  newDefaults({commit, }, costs) {
    commit('setDefaults', {})
    if (!costs) return;

    const defaults = costs.data.reduce((obj, item) => {

      if (!(item.finance_form in obj))
       obj[item.finance_form] = {
         months: item.months,
         distance: item.distance,
         price: getPrice(item),
         residual: item.residual,
         id: item.id,
         downPayment: item.down_payment
        };

      const {months, distance, price, residual, id, downPayment} = obj[item.finance_form];

      const generalPrice = getPrice(item);

      const check = generalPrice < price || (generalPrice === price && item.months < months) || ((generalPrice === price && item.months === months && item.distance < distance));

      obj[item.finance_form]['months'] =  check ? item.months : months;

      obj[item.finance_form]['distance'] = check ? item.distance : distance;

      obj[item.finance_form]['price'] = check ? generalPrice : price;

      obj[item.finance_form]['residual'] = check ? item.residual : residual;

      obj[item.finance_form]['id'] = check ? item.id : id;

      obj[item.finance_form]['downPayment'] = check ? item.downPayment : downPayment;

      return obj
    }, {})

    commit('setDefaults', defaults);
  },
  updateDefaults({commit, state, }, data) {
    const { form, months, distance, price, residual, id, downPayment} = data;

    const copyDefaults = JSON.parse(JSON.stringify(state.defaults));

    if (months) copyDefaults[form]['months'] = months

    if (distance) copyDefaults[form]['distance'] = distance

    if (price) copyDefaults[form]['price'] = price

    if (residual) copyDefaults[form]['residual'] = residual

    if (id) copyDefaults[form]['id'] = id

    if (!isNaN(downPayment)) copyDefaults[form]['downPayment'] = downPayment

    commit('setDefaults', copyDefaults);
  },
  updatePriceForm({commit}, form) {
    commit('setPriceForm', form);
  },
  updateResidualVisibility({commit}, form) {
    commit('setResidualVisibility', form);
  },
  updatePersonNumberError({commit}, errorString) {
    commit('setPersonNumberError', errorString)
  },
}