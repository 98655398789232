import Vue from 'vue'
import Router from 'vue-router'
import VueAnalytics from 'vue-analytics'

import reseller from './middleware/reseller'

import store from './store/index'

import { 
	googleAccount,
	googleAccountRenault,
	googleAccountDacia,
	googleAccountDanmark,
	googleAccountDanmarkDacia } from '@/config/defaultValues'

Vue.use(Router);

const Home = () => import('./views/Home');
const Product = () => import('./views/Product');
const Confirmed = () => import('./views/Confirmed');
const NotFound = () => import('./views/404');
const NoOrder = () => import('./views/NoOrder');

const CookiePolicy = () => import('./views/CookiePolicy');

const router = new Router({
	linkActiveClass: 'active',
	mode: 'history',
	routes: [
		{
			path: '/:collectionType/cookiepolicy',
			name: 'cookiepolicy',
			component: CookiePolicy,
			meta: {
				middleware: [reseller]
			},
		},
		{
			path: '/:collectionType?',
			name: 'home',
			component: Home,
			meta: {
				middleware: [reseller]
			},
			beforeEnter: (to, from, next) => {
				if (from.matched.length) {
					history.pushState({}, null, to.path);
					next()
				} else {
					next()
				}
			}
		},
		{
			path: '/:collectionType/product/:type/:brand/:model/:name',
			name: 'product',
			component: Product,
			meta: {
				middleware: [reseller]
			},
		},
		{
			path: '/:collectionType/product/:type/:brand/:model/:name/confirmed/:key?',
			name: 'confirmed',
			component: Confirmed,
			meta: {
				middleware: [reseller]
			},
		},
		{
			path: '/:collectionType/:landingUrl*',
			name: 'home',
			component: Home,
			meta: {
				middleware: [reseller]
			},
		},
		{
			path: '/:collectionType/*',
			name: 'notfound',
			component: NotFound
		},
		{
			path: '/:collectionType/noorder',
			name: 'noorder',
			component: NoOrder
		}

	]
});

router.afterEach(() => {
  store.commit('global/disablePreLoader')
});

const isDanmarkR = window.location.origin.includes('renault.dk') || window.location.origin.includes('renaultdanmark');
const isDanmarkD = window.location.origin.includes('dacia.dk');

const isRenault = window.location.origin.includes('renault');
const isDacia = window.location.origin.includes('dacia');

if (
		googleAccount.some(item => item) && 
		!(isDanmarkR || isDanmarkD || isRenault || isDacia)
	) {
	Vue.use(VueAnalytics, {
		id: googleAccount,
		router,
		autoTracking: {
			pageviewOnLoad: true
		},
		ecommerce: {
			enabled: true,
			enhanced: true
		},
	})
}

router.beforeEach((to, from, next) => {

	if (
		!document.querySelector('html').code &&
		!document.documentElement.lang &&
		!(isDanmarkR || isDanmarkD || isRenault || isDacia)
	) return next();

	let acc = isDanmarkR ? googleAccountDanmark : 
    isDanmarkD ? googleAccountDanmarkDacia : 
    isRenault ? googleAccountRenault : 
    isDacia ? googleAccountDacia : '';

		// eslint-disable-next-line
		dataLayer.push({
			brand: window.location.origin.includes('renault') ? 'Renault' : 'Dacia',
			googleAccount: acc,
			pageName: to.name,
			businessType: 'new-car',
			pageType: 'finance-simulator',
			// pageType: to.name === 'product' ? 'form' : 'finance-simulator',
			languageCode: document.documentElement.lang,
			countryCode: document.querySelector('html').code,
		});

  return next();
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  return next();
});

export default router
