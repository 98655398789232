export default {
  getAllFilterData({commit, state}, data) {
    const fixedField = data ? data.name : null;

    // eslint-disable-next-line
    return axios.get(`/filter/types/${state.resellerTypeId}/items`, {
      headers: {
        'Authorization': this.state.reseller.partnerKey,
      },
      params: {
        'financeFormId': state.finance_form_id,
        ...state.selectedFilters
      }
    })
    .then(response => {
      const filteredData = response.data.filters.data.filter(item => item.key !== fixedField);
      let getFixedData;

      if (fixedField !== null) {
        getFixedData = state.filters.find(item => item.key === fixedField);

        const newData = response.data.filters.data.map(item => {
          if (item.key === fixedField) {
            item.items = getFixedData.items;
            
            return item
          }

          return item
        })

        commit('setFilters', newData);
      } else {
        commit('setFilters', filteredData);
      }

      return filteredData
    })
    .catch(response => {

      return response
    })
  },
  resetFilters({commit}) {
    commit('removeFiltersData');
  },
  updateFinanceForm({commit, }, form) {
    commit('setFinanceFormId', form.id);
    commit('setFinanceFormName', form.name);
  },
  updateFinanceFormId({commit, }, id) {
    commit('setFinanceFormId', id);
  },
  updateFinanceFormName({commit, }, name) {
    commit('setFinanceFormName', name);
  },
  getDefaultFinanceFormId({dispatch, }, financeFormsArr) {
    const urlForm = window.location.search.replace('?', '').split('&').find(item => item.includes('financeform'));
    const urlFormName = urlForm ? urlForm.replace('financeform=', '').replace('-', ' ') : '';

    let defaultForm = financeFormsArr.find(item => {
      if (urlFormName) {
        return item.name.toLowerCase() === urlFormName && item.type.toLowerCase() === this.state.global.generalFormsCollectionName;
      } else {
        return item.default && item.type.toLowerCase() === this.state.global.generalFormsCollectionName;
      }
    });

    // if collection type has param (user set it in url) but reseller doesn't have
    // finance forms with this type, set the opposite collection type and redirect user

    if (!defaultForm && financeFormsArr.length) {
      const url = this.state.global.generalFormsCollectionName === "private" ? 'foretag' : 'privat';

      location.href = window.location.origin + '/' + url

      return;
    }
    
    dispatch('updateFinanceForm', defaultForm);
  }
}