import Vue from 'vue';
import axios from 'axios';

let config = {
	baseURL: process.env.VUE_APP_API_URL,
	withCredentials: true,
	headers: {
		'Accept': 'application/json',
		'X-Partner-Key': process.env.VUE_APP_X_PARTNER_KEY,
		'Content-Type': 'application/json',
	}
};

const AxiosPlugin = {

	install(Vue, options) {
		Vue.axios = window.axios = axios.create(options)
	}
};

Vue.use(AxiosPlugin, config);