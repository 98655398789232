require('./bootstrap');

import Vue from 'vue'
import App from './App.vue'
import * as VeeValidate from 'vee-validate'
import VueGtm from 'vue-gtm'
import VueTagManager from "vue-tag-manager"
import VueMeta from 'vue-meta'
import { i18n } from './lang'
import router from './router'
import store from './store'

import '@/plugins/axios'
import PreLoader from '@/components/helps/PreLoader'
import underscore from 'vue-underscore';

import VueTheMask from 'vue-the-mask'
import {gtmIdRenaultSE, gtmIdRenaultDK, gtmIdDaciaSE, gtmIdDaciaDK} from '@/config/defaultValues';

import * as Sentry from "@sentry/vue";

Sentry.init({
  dsn: "https://bebff4cc2bb94b2b8dfe582fed9765ae@app.glitchtip.com/3073",
  Vue: Vue,
});


Vue.use(VueTheMask);
Vue.use(underscore);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

if (window.location.origin.includes('finans.renault.se')) {

  Vue.use(VueGtm, {
    id: gtmIdRenaultSE,
    enabled: true,
    debug: true,
    loadScript: true,
  });

  Vue.use(VueTagManager, {
    gtmId: gtmIdRenaultSE,
  });
}

if (window.location.origin.includes('privatleasing.renault.dk')) {

  Vue.use(VueGtm, {
    id: gtmIdRenaultDK,
    enabled: true,
    debug: true,
    loadScript: true,
  });

  Vue.use(VueTagManager, {
    gtmId: gtmIdRenaultDK,
  });
}

if (window.location.origin.includes('privatleasing.dacia.dk')) {

  Vue.use(VueGtm, {
    id: gtmIdDaciaDK,
    enabled: true,
    debug: true,
    loadScript: true,
  });

  Vue.use(VueTagManager, {
    gtmId: gtmIdDaciaDK,
  });
}

if (window.location.origin.includes('finans.dacia.se')) {

  Vue.use(VueGtm, {
    id: gtmIdDaciaSE,
    enabled: true,
    debug: true,
    loadScript: true,
  });

  Vue.use(VueTagManager, {
    gtmId: gtmIdDaciaSE,
  });
}

VeeValidate.Validator.extend('phone', {
  validate: value => !!value.match(/^(\+?[0-9]+)$/)
});

Vue.use(VeeValidate);

Vue.config.productionTip = true;

Vue.component('pre-loader', PreLoader);

localStorage.removeItem('resellerInfo');

if (process.env.VUE_APP_CHAT_ID) {
  window._sid = process.env.VUE_APP_CHAT_ID;

  (function() {
    var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
    se.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'files.imbox.io/app/dist/initWidget.js';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
  })();
}

// eslint-disable-next-line no-unused-vars
let app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
