export default {
  ordersList: {},
  status: 1,
  vehicleId: '',
  vehicleColorId: '',
  vehicleCostId: '',
  vehicleCostDownPaymentId: '',
  locationId: '',
  postal_code_id: '',
  home_delivery: false,
  accessories: [],
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  personNumber: '',
  companyName: '',
  pickUpDate: '',
  pickUpTime: '',
  companyNumber: '',
  address: '',
  coAddress: '',
  city: '',
  zipCode: '',
  comments: '',
  totalMonthlyPrice: '',
  drivingLicense: '',
  residual: 0,
  cashPayment: 0,
  isTradeIn: false,
  tradeIn: {},
  kalp_data: {},
}