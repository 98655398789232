/**
 * Returns the periodic payment for an annuity.
 *
 * Category: Financial
 *
 * @param {*} rate The interest rate for the loan.
 * @param {*} numberOfPeriods The total number of payments for the loan.
 * @param {*} presentValue The present value, or the total amount that a series of future payments is worth now; also known as the principal.
 * @param {*} futureValue Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.
 * @param {*} paymentDue Optional. The number 0 (zero) or 1 and indicates when payments are due.
 * @returns
 */
export const PMT = (rate, numberOfPeriods, presentValue, futureValue = 0, paymentDue = 0) => {
    if (rate === 0) return -(presentValue + futureValue) / numberOfPeriods;
    const term = (1 + rate) ** numberOfPeriods
    if (paymentDue === 1) return -((futureValue * rate) / (term - 1) + (presentValue * rate) / (1 - 1 / term)) / (1 + rate)
    return -((futureValue * rate) / (term - 1) + (presentValue * rate) / (1 - 1 / term))
}