<template>
  <div>
    <footer 
      class="noindex" 
      v-if="Object.keys(categories).length > 0"
    >
      <div class="container">
        <nav>
          <div class="row">
            <dl class="col-xl-3 col-md-3 col-sm-6 footer-column" 
                v-for="(footerItem, index) in categories" 
                :key="index">
              <dt class="footer-title">
                <span>{{footerItem.name}}</span>
              </dt>
              <div v-if="footerItem.items.data.length > 0">
                <dd 
                  v-for="(item, index) in footerItem.items.data" 
                  :key="index" 
                  :class="{'social-item': item.type === 'Image'}"
                >
                  <a v-if="item.type === 'Text'"
                    :href="item.url" 
                    target="_blank" 
                    rel="nofollow noopener">
                    <span>{{item.name}}</span>
                  </a>
                  <a v-if="item.type === 'Image'" class="social-icon"
                    :style="{ 'background-image': `url(${item.image})` }"
                    :href="item.url" 
                    target="_blank" 
                    rel="nofollow noopener"></a>
                </dd>
              </div>
            </dl>
          </div>
        </nav>
      </div>
      <div 
        class="footer-bottom" 
        v-if="resellerInfo.copyright"
      >
        <div class="container">
          <nav>
            <div class="footer-flex">
              <div class="footer-legal">
                <a href="#!" :title="resellerInfo.name">
                  &#169; {{ resellerInfo.copyright }}
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </footer>
    <div class="footer-powered" v-if="isPoweredVisible">
      <div class="container">
        <b>{{ $t("footer.poweredBy") }}: </b> 
        <a href="https://www.ecarsuite.com/" target="_blank" rel="nofollow noopener">
          E-CAR Suite</a>, {{ $t("footer.productFrom") }} Leaseonline
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'VFooter',
    computed: {
      ...mapState('reseller', ['resellerInfo', 'customStyle']),
      categories () {
        return this.customStyle.footerCategories?.data || {};
      },
      isPoweredVisible() {
        if (Object.keys(this.resellerInfo).length) {
          return this.resellerInfo.url.indexOf('bebiluthyrning') === -1
        }

        return ''
      }
    },
  }
</script>

<style lang="sass">
  @import '@/style/components/Footer.sass'
</style>
