/* eslint-disable no-prototype-builtins */
import {getDaysOrWeeks,} from '@/helpers/helpers.js';

export default {
  fixedCostByDistanceAndMonth: (state) => {
    if (state.vehicle.costs && Object.keys(state.defaults).length) return state.vehicle.costs.data
      .find(item =>
        item.finance_form === state.priceForm &&
        item.months === state.defaults[state.priceForm].months &&
        item.distance === state.defaults[state.priceForm].distance
      ) || {}
  },
  vehicleNotEmpty: (state) => JSON.stringify(state.vehicle) !== '{}',

  getCurrentPricesObject: (state) => state.vehicle.prices?.data.find(({finance_form}) => finance_form === state.priceForm),
  getCurrentCostsObject: (state, getters, rootState) => state.vehicle.costs?.data.find(({id}) => id == rootState.order.vehicleCostId),

  getIsVatIncluded: (state) => {
    if (state.vehicle.costs && Object.keys(state.defaults).length && state.priceForm) {
      const costObj = state.vehicle.costs.data
        .find(item =>
          item.finance_form === state.priceForm &&
          item.months === state.defaults[state.priceForm].months &&
          item.distance === state.defaults[state.priceForm].distance
        );

      return costObj ? costObj.vat_included : false;
    }
  },
  deliveryDaysCountFrom: (state, getters, rootState) => {
    const vehicleInfo = JSON.parse(localStorage.getItem('vehicleInfo'))

    const days = state.vehicleColor && 'delivery_days_from' in state.vehicleColor && state.vehicleColor.delivery_days_from !== null ?
      state.vehicleColor.delivery_days_from :
      state.vehicle && 'deliveryTime' in state.vehicle ? state.vehicle.deliveryTime.from : 0;

    const actualDays = vehicleInfo !== null && vehicleInfo.vehicleColor &&
    window.location.pathname.includes('/confirmed/') ? vehicleInfo.vehicleColor.delivery_days_from : days

    return getDaysOrWeeks(actualDays, 'short', rootState.reseller.resellerInfo?.market?.lang || 'sv');
  },
  deliveryDaysCountTo: (state, getters, rootState) => {
    const vehicleInfo = JSON.parse(localStorage.getItem('vehicleInfo'))

    const days = state.vehicleColor && 'delivery_days_to' in state.vehicleColor && state.vehicleColor.delivery_days_to !== null ?
      state.vehicleColor.delivery_days_to :
      state.vehicle && 'deliveryTime' in state.vehicle ? state.vehicle.deliveryTime.to : 0;

      const actualDays = vehicleInfo !== null && vehicleInfo.vehicleColor &&
      window.location.pathname.includes('/confirmed/') ? vehicleInfo.vehicleColor.delivery_days_to : days

    return getDaysOrWeeks(actualDays, 'short', rootState.reseller.resellerInfo?.market?.lang || 'sv');
  },
  deliveryDaysCount: (state) => state.vehicleColor ? state.vehicleColor.delivery_days_from : state.vehicle.deliveryTime.days_count,
  grade: state => state.vehicleColor?.grade || state.vehicle.grade,
  getDeliveryDaysCount: (state) => state.vehicleColor ? state.vehicleColor.delivery_days_to : 0,
  getBusinessType: (state) => {
    return state.vehicle.new ? 'new-car' : 'used-car'
  },
  getFormCategory: (state) => {
    return state.vehicle.new ? 'lead_newcar' : 'lead_usedcar'
  },
  getDefaults: state => state.defaults,
  getPriceForm: state => state.priceForm,
  getResidualVisibility: state => state.residualVisibility,
  getPersonNumberError: state => state.personNumberError,
}