export default {
  steps: [
    {
      number: 1,
      btnLabel: 'Vidare till tillval',
      title: 'Grundval / Tillval'
    },
    {
      number: 2,
      btnLabel: 'Slutför beställning',
      title: 'Beställ nu'
    },
    {
      number: 3,
      btnLabel: '',
      title: 'Bekräftelse'
    },
  ],
  nfSteps: [
    {
      index: 1,
      btnLabel: 'Color',
      title: 'Färg',
      titleDk: 'FARVER',
      isVisible: true
    },
    {
      index: 2,
      btnLabel: 'Period',
      title: 'AVTALSLÄNGD & KÖRSTRÄCKA',
      titleDk: 'KM-FORBRUG OG UDBETALING',
      isVisible: true
    },
    {
      index: 3,
      btnLabel: 'Options',
      title: 'Tillval',
      titleDk: 'TILVALG',
      isVisible: true
    },
    {
      index: 4,
      btnLabel: 'Winter tyres',
      title: 'VINTERHJUL',
      titleDk: 'VINTERDÆK',
      isVisible: true
    },
    {
      index: 5,
      btnLabel: 'Insurance',
      title: 'Försäkring',
      titleDk: '',
      isVisible: true
    },
    {
      index: 6,
      btnLabel: 'Trade in',
      title: 'Inbytesbil',
      titleDk: '',
      isVisible: true
    },
    {
      index: 7,
      btnLabel: 'Order',
      title: 'Skicka ANSÖKAN',
      titleDk: 'ANSØGNING',
      isVisible: true
    },
  ],
  nfActiveStep: {
    index: 1,
    btnLabel: 'Color',
    title: 'Färg',
    titleDk: 'FARVER',
  },
  currentStepIndex: 0,
  redirectUrl: '',
  sortedCosts: {},
}