import {camelCaseToUnderScoreCase} from "@/utils/strings";

const currentOrder = {};

export default {
  clearAll({state, commit}) {
    for (let field in state) {
      if (field !== 'accessories') {
        commit('flexSetField', [field, ''])
      } else {
        commit('flexSetField', [field, []])
      }
    }
    localStorage.removeItem('order')
    localStorage.removeItem('vehicleImage')
  },
  fillAllFields({commit}) {
    let order = JSON.parse(localStorage.getItem('order'));

    if (order) {
      for (let key in order) {
        let value = order[key];
        commit('flexSetField', [key, value])
      }
    }
  },
  saveAllFields({commit}, order) {
    let existingOrder = JSON.parse(localStorage.getItem('order'));
    const params = window.location.search;
    const isHasId = params !== '' && params.indexOf('?id=') !== -1;
    const currentVehicleId = isHasId ? parseInt(params.replace('?id=', '')) : null;

    if (currentVehicleId && !currentOrder[currentVehicleId]) {
      currentOrder[currentVehicleId] = {};
    }

    if (existingOrder) {
      for (let key in order) {
        if (!order[key]) break;

        existingOrder[key] = order[key];

        currentOrder[currentVehicleId][key] = order[key];

        const commitName = `set${key[0].toUpperCase()}${key.slice(1)}`;

        commit(commitName, order[key]);
      }
      order = existingOrder;
    }

    localStorage.setItem('order', JSON.stringify(order));
    commit('setOrdersList', currentOrder);
  },
  postOrder({state, rootGetters}) {
    const params = window.location.search;
    const isHasId = params !== '' && params.indexOf('?id=') !== -1;
    const currentVehicleId = isHasId ? parseInt(params.replace('?id=', '')) : null;
    const order = state.ordersList[currentVehicleId];
    const localStorageOrder = JSON.parse(localStorage.getItem('order'));

    const removeUrlsFromInput = (input) => {
      if (!input || typeof input !== 'string') return input

      const regex = /(?:https?|ftp):\/\/[\n\S]+/g
      //remove also the tags except of @ and #
      const regex2 = /<[^@#>]+>/g

      return input.replace(regex, '').replace(regex2, '')
    }


    const fieldsToFilterOut = ['orders_list'];

    if (!['Down payment', 'Corporate leasing', 'VAT credit'].includes(this.state.product.priceForm))
      fieldsToFilterOut.push('residual', 'cashPayment');
0
    if (!['Corporate rental', 'Corporate leasing', 'VAT credit'].includes(this.state.product.priceForm))
      fieldsToFilterOut.push('company_name', 'company_number');

    if (!['Private rental', 'Corporate rental'].includes(this.state.product.priceForm))
      fieldsToFilterOut.push('pick_up_date', 'pick_up_time');

    if (!['Corporate leasing', 'Corporate rental', 'VAT credit'].includes(this.state.product.priceForm))
      fieldsToFilterOut.push('company_name', 'company_number');

    if (!this.state.product.residualVisibility) fieldsToFilterOut.push('residual');

    if (this.state.product.deliveryType === 'delivery_type_stardard') fieldsToFilterOut.push('postal_code_id');
    else if (this.state.product.deliveryType === 'delivery_type_home') fieldsToFilterOut.push('location_id');

    if (!this.state.reseller.resellerInfo.settings.tradeIn.active || !state.isTradeIn)
      fieldsToFilterOut.push('trade_in');

    if (!this.state.reseller.resellerInfo.settings.drivingLicense)
      fieldsToFilterOut.push('driving_license');

    if (!this.state.reseller.resellerInfo.settings.personNumber)
      fieldsToFilterOut.push('person_number');

    const data = Object.fromEntries(
        Object.entries(state)
            .map(([key, value]) => [
                key === 'tradeIn' ? key : camelCaseToUnderScoreCase(key),
                removeUrlsFromInput(value || order[key] || localStorageOrder[key])
            ]).filter(([key])=> !fieldsToFilterOut.includes(key))
    )

    data.total_monthly_price = rootGetters['financing/totalMonthlyCost'] || 0;
    data.cash_payment = rootGetters['financing/cashPaymentPercentage'] || 0;
    data.residual = rootGetters['financing/residualValuePercentage'] || 0;

    // axios is globally declared in Vue and window for some reason - todo: move it to a axios instance
    // eslint-disable-next-line no-undef
    return axios.post('/orders', data, {
      headers: {
        'Authorization': this.state.reseller.partnerKey,
      }
    })
  }
}