export default {
  getRouteParams: (state, ) => {
    const currentType = state.allTypes.find(item => state.type.includes(item.type.id));
    const paramsObj = {
      type: currentType.type.name,
    };

    for(let param in state.selectedFilters) {
      if (paramsObj[param]) paramsObj[param] = []

      const filterParam = state.filters.find(item => item.key === param);

      const items = Array.isArray(filterParam.items) ? filterParam.items : Object.values(filterParam.items)

      paramsObj[param] = (
        items.reduce((names, next) => {
          if (state.selectedFilters[param].includes(next.key)) names.push(next.value)

          return names;
        }, [])
      );
    }

    return paramsObj;
  },
  getUrlComponents: state => Object.keys(state.filters),
  getType: state => state.type,
  getAllTypes: state => state.allTypes,
  getFilters: state => state.filters,
  getResellerTypeId: state => state.resellerTypeId,
  sortedModels: () => {
    return (models) => {
      if (!models.length) return models;

      const elementToTheTop = ['nya captur', 'nya zoe', 'nya clio', ];
      let newArr = [];

      models.forEach(item => {
        const itemName = item.value.toLowerCase();

        if (elementToTheTop.includes(itemName)) {
          if (itemName === 'nya captur') {
            newArr.splice(0, 0, item);
          } else {
            newArr.splice(1, 0, item);
          }
        } else {
          newArr.push(item)
        }
      })

      return newArr
    
    }
  },
  getFinanceFormId: (state) => state.finance_form_id,
  getFinanceFormName: (state) => state.finance_form_name,
  getFilteredFinanceForms: state => form => {
    const financeForms = state.allFinanceForms;

    if (!financeForms.length) return [];

    const filteredFinanceForms = financeForms.filter(item => 
      item.type.toLowerCase() === form
    );

    return filteredFinanceForms
  },
  getAbstractFormsCollection: (state) => {
    if (!state.allFinanceForms) return [];

    const sortedCollection = [ ...new Set(state.allFinanceForms.map(item => item.type))];

    return sortedCollection[0] !== 'Private' ? sortedCollection.reverse() : sortedCollection;
  },
}