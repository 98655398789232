
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import da from './lang/da.json'
import en from './lang/en.json'
import fi from './lang/fi.json'
import nn from './lang/nn.json'
import sv from './lang/sv.json'

Vue.use(VueI18n)
// translations

const messages = {
  da, en, fi, nn, sv,
}

// Create VueI18n instance with options
// sv - Swedish - on default

export const i18n = new VueI18n({
  locale: 'sv', // set locale
  messages, // set locale messages
})