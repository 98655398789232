import { i18n } from './../lang'

/* eslint-disable no-useless-escape */
export const getPrice = (costs) => {
    let extraPrice = 0;
    const serviceCost = costs.service_cost !== null ? costs.service_cost : 0;

    if (costs.extraFields.data.length) {
        extraPrice = costs.extraFields.data.reduce((sum, item) => {
            return sum + item.price;
        }, 0)
    }

    return costs.calculated_price + serviceCost + extraPrice;
};

export const getDaysOrWeeks = (daysNumber, flag, lang) => {
    const isWeek = daysNumber % 7 === 0;
    const rangeCount = isWeek ? daysNumber / 7 : daysNumber;
    // eslint-disable-next-line no-undef
    const rangeName = isWeek ? i18n.t('weeks', lang || 'sv') : i18n.t('days', lang || 'sv');
    const rangeFinalName = flag ? (flag === 'short' ? rangeName.charAt(0) + '.' : rangeName) : '';

    return {'number': rangeCount, 'text': rangeFinalName};
};

export const parseCss = input => {
    let outputCss = '';

    function parseProperties(properties) {
        let parsedProperties = '';

        for (let property in properties) {
            if (!(property in properties)) {
                continue;
            }

            parsedProperties += ` ${property}: ${properties[property]}; `;
        }
        return parsedProperties;
    }

    for (let rule in input) {
        if (!(rule in input)) {
            continue;
        }

        if (rule === '@font-face') {
          input[rule].map(
            (item) => (outputCss += ` @font-face { ${parseProperties(item)} } `)
          )
    
          continue
        }

        if (rule === '@media') {
            input[rule].map(item => outputCss += ` @media (${item.condition.join(') and (')}) { ${parseCss(item.content)} } `);
            continue;
        }

        outputCss += ` ${rule} { ${parseProperties(input[rule])} } `;
    }

    return outputCss;
};

export const buildMediaQuery = (conditions, rules) => {
    return {
        condition: conditions,
        content: rules
    }
};

export const isMobileWidth = () => window.matchMedia("(max-width: 480px)").matches

export const hexToRgbA = (hex, alpha) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
};

export const getGearName = (key) => {
	switch(key) {
		case 1:
		return 'manual';

		case 2:
		return 'auto'
	}
};

export const formatPrice = (num) => {
  //console.log('number', num)
    if (!num || num === 0) return num;
    return Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

export const formatPriceNegative = (num) => {
    if (!num || num === 0) return num;
    if (num < 0) {
        return `- ${num * -1}`
    } else {
        return `+ ${num}`
    }
};

export const replaceSpaces = (text) => text.replace(/ /g, '-').toLowerCase();

export const isMobile = () => {
    let isMobile = false;
    
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
      isMobile = true;
    }

    return isMobile;
  }

export const getCollectionName = (item, flag) => {
  switch(item.toLowerCase()) {
    case 'private':
      return 'privat'

    case 'corporate':
      return flag === 'url' ? 'foretag' : 'företag'
  }
}

export const getProperCollectionName = (url) => {
  switch(url) {
    case 'privat':
      return 'private'

    case 'foretag':
      return 'corporate'
  }
}

export const isPrivateType = (form) => {
  return ['private leasing', 'private rental', 'down payment'].includes(form.toLowerCase());
}

export const isCorporateType = (form) => {
  return ['corporate rental', 'corporate leasing', 'vat credit'].includes(form.toLowerCase());
}

export const isFormsWithResidual = (form) => {
  return ['down payment', 'corporate leasing', 'vat credit'].includes(form.toLowerCase());
}

export const isRental = (form) => {
  return ['corporate rental', 'private rental'].includes(form.toLowerCase());
}

export const camelize = (str) => {
  return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const isRenault = () => {
  return window.location.origin.includes('renault')
}

export const isDacia = () => {
  return window.location.origin.includes('dacia')
}

export const isDanmarkR = () => {
  return 'renault.ecarsuite.com'.includes('renault.dk') || 'renault.ecarsuite.com'.includes('renaultdanmark') //window.location.origin.includes('renault.dk') || window.location.origin.includes('renaultdanmark');
}

export const isDanmarkD = () => {
  return window.location.origin.includes('dacia.dk');
}

export const isDanmark = () => {
  return window.location.origin.includes('dacia.dk') || window.location.origin.includes('renault.dk');
}

export const isDesktop = () => {
  return window.innerWidth > 998
}

export const splitUp = (arr, n) => {
    let rest = arr.length % n,
        restUsed = rest,
        partLength = Math.floor(arr.length / n),
        result = [];

    for(let i = 0; i < arr.length; i += partLength) {
        let end = partLength + i,
            add = false;

        if(rest !== 0 && restUsed) {
            end++;
            restUsed--;
            add = true;
        }

        result.push(arr.slice(i, end));

        if(add) {
            i++;
        }
    }

    return result;
}