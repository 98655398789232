export default {
  getVehicles({commit, state, getters}, toNextPage = true) {
    
    // eslint-disable-next-line no-undef
    return axios.get('/vehicles', {
      headers: {
        'Authorization': this.state.reseller.partnerKey,
      },
      params: {
        'financeFormId': this.state.filter.finance_form_id,
        'typeId': this.state.filter.type[0],
        page: toNextPage ? getters.nextPage : 1,
        perPage: 9,
        sort: state.selectedTypeOfSort,
        ...this.state.filter.selectedFilters,
      }
    })
    .then((response) => {
      if (!toNextPage) {
        commit('setVehicles', response.data.data);
      } else {
        commit('appendVehicles', response.data.data);
      }

      commit('setVehiclesLoaded', true);

      commit('setPagination', response.data.meta.pagination);

      return response.data
    })
    .catch(response => {

      return response
    })
  },
}