import store from "../store";

const setNewResellerData = async () => {
  await store.dispatch('reseller/getResellerData');

  await store.dispatch('reseller/updateBuilder');
}

export default function reseller({ next }) {
  let resellerInfo = JSON.parse(localStorage.getItem('resellerInfo'));

  !resellerInfo ? setNewResellerData() : store.commit('reseller/setResellerInfo', resellerInfo);

  return next();
}
